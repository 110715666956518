<template>
  <div class="px-4 py-4">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer active font-medium w-auto"
        @click="e => openTab(e, 'dadosCadastrais')"
      >
        Dados Cadastrais
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'dadosComplementares')"
      >
        Dados Complementares
      </button>
      <button
        v-if="profileHelpers.isInsurance() && profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_ANALISE_DE_CREDITO
              ])"
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'analiseCredito')"
      >
        Análise de Crédito
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="e => openTab(e, 'dadosBancarios')"
      >
        Dados Bancários
      </button>
    </div>
    <div id="dadosCadastrais" class="tabContent active">
      <vs-row
        v-if="
          profileHelpers.checkPermission([
            profileHelpers.menuFuncionalitiesEnum.APROVACAO_MANUAL,
            profileHelpers.menuFuncionalitiesEnum.REPROCESSAMENTO,
            profileHelpers.menuFuncionalitiesEnum.IGNORAR_ASSINATURA_DE_CCG
          ])
        "
        class="mt-6"
        vs-type="inline-flex"
      >
        <vs-col
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.APROVACAO_MANUAL
            ]) && tomadorDetail.ApprovedByCreditEngine == false
          "
          class="w-auto"
        >
          <el-button type="success" @click="openModalForcarAprovacao = true"
            >Aprovar Manual</el-button
          >
        </vs-col>

        <vs-col
          class="w-auto"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.REPROCESSAMENTO
            ])
          "
        >
          <vs-button
            color="primary"
            type="border"
            :disabled="!permiteReprocessamento"
            @click="openModalReprocessamento = true"
            >Reprocessamento</vs-button
          >
          <vx-tooltip
            v-if="!permiteReprocessamento"
            text="Desabilitado pois tomador já possui apólices emitidas"
            position="right"
            class="enable-ccg"
          >
            <i class="material-icons">info</i>
          </vx-tooltip>
        </vs-col>

        <vs-col
          class="w-1/2 flex text-right"
          v-if="
            profileHelpers.checkPermission([
              profileHelpers.menuFuncionalitiesEnum.IGNORAR_ASSINATURA_DE_CCG
            ])
          "
        >
          <label>
            {{
              verificarMapfreCorretor()
                ? " CCG regularizado"
                : " Ignorar assinatura de CCG"
            }}
          </label>
          <vx-tooltip
            text="Ao ativar essa opção, a assinatura do CCG não será obrigatória para emitir propostas"
            position="right"
            class="enable-ccg"
          >
            <i class="material-icons">info</i>
          </vx-tooltip>
          <vs-switch
            class="ml-2"
            @click="invertCcgSignature(tomadorDetail.UniqueId)"
            v-model="tomadorDetail.IgnoreCCGSignature"
          >
            <span slot="on">Sim</span>
            <span slot="off">Não</span>
          </vs-switch>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col>
          <vs-input
            class="w-full"
            disabled="true"
            @icon-click="openModalUpdateName()"
            label="Razão Social"
            :value="tomadorDetail.Name"
            :icon="
              podeEditar && profileHelpers.isInsurance() ? 'mode_edit' : ''
            "
            size="large"
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="w-1/4">
          <vs-input
            label="CNPJ"
            disabled="true"
            :value="tomadorDetail.CpfCnpj"
            size="large"
            v-mask="['##.###.###/####-##']"
          />
        </vs-col>

        <vs-col class="w-1/4">
          <vs-input
            v-if="tomador.CreateDate != undefined"
            label="Data de registro"
            disabled="true"
            :value="
              new Date((tomador || {}).CreateDate) | dateFormat('DD/MM/YYYY')
            "
            size="large"
          />
        </vs-col>
        <vs-col class="w-1/4">
          <vs-input
            v-if="profileHelpers.isInsurance()"
            disabled="true"
            @icon-click="openModalUpdateDate()"
            class="w-full"
            label="Data de validade cadastral"
            :icon="podeEditar ? 'mode_edit' : ''"
            :value="
              new Date(tomadorDetail.ExpirationDate) | dateFormat('DD/MM/YYYY')
            "
            size="large"
          />
          <vs-input
            v-else
            disabled="true"
            class="w-full"
            label="Data de validade cadastral"
            :value="
              new Date(tomadorDetail.ExpirationDate) | dateFormat('DD/MM/YYYY')
            "
            size="large"
          />
        </vs-col>
        <vs-col class="w-1/4">
          <vs-input
            v-if="tomadorDetail.LimitsAndRatesRecalculatedDate"
            class="w-full"
            label="Data recadastro"
            disabled="true"
            :value="
              new Date((tomadorDetail || {}).LimitsAndRatesRecalculatedDate)
                | dateFormat('DD/MM/YYYY')
            "
            size="large"
          />
          <vs-input
            v-else
            class="w-full"
            label="Data recadastro"
            disabled="true"
            :value="'Sem registro'"
            size="large"
          />
        </vs-col>
      </vs-row>

      <vs-row v-if="profileHelpers.isInsurance()">
        <vs-col class="w-1/4">
          <vs-input
            disabled="true"
            @icon-click="openModalUpdateComission()"
            label="Comissão"
            :value="tomadorDetail.ComissionPercentage + '%'"
            :icon="podeEditar ? 'mode_edit' : ''"
            size="large"
          />
        </vs-col>

        <vs-col class="w-1/2">
          <vs-input
            disabled="true"
            @icon-click="exibirModalOpcoesCoretorNomeado()"
            label="Corretor Nomeado"
            :value="
              tomadorDetail.NominatedToBroker
                ? tomadorDetail.NominatedToBroker.Name
                : 'Sem nomeação'
            "
            :icon="podeEditar ? 'mode_edit' : ''"
            size="large"
          />
        </vs-col>
        <vs-col class="w-1/4">
          <vs-input
            disabled="true"
            label="Data de validade da nomeação"
            :value="tomadorDetail.NomeationExpireDate ? formatarData(tomadorDetail.NomeationExpireDate) : '-'"
            size="large"
          />
        </vs-col>
      </vs-row>

      <template v-if="profileHelpers.isInsurance()">
        <vs-row>
          <vs-col class="w-1/3" >
            <vs-input
              class="w-full"
              label="Data de fundação"
              disabled="true"
              :value="dadosDoRisco.CompanyInaugurationDate ? formatarData(dadosDoRisco.CompanyInaugurationDate) : '-'"
              size="large"
            />
          </vs-col>
          <vs-col class="w-1/3" >
            <vs-input
              label="Obs. Fundação"
              disabled="true"
              class="w-full"
              :value="dadosDoRisco.CompanyInaugurationDescription"
              size="large"
            />
          </vs-col>
          <vs-col class="w-1/3" >
            <vs-input
              label="Situação"
              disabled="true"
              value="ATIVO"
              size="large"
              :icon="
             userInfo.ProfileName ==='RiskAdviser' ? 'mode_edit' : ''
            "
            @icon-click="consultarWSDialog = !consultarWSDialog"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/3">
            <vs-input
              label="CNAE "
              disabled="true"
              :value="filterCNAEs(tomadorDetail.CNAEs)"
              size="large"
            />
          </vs-col>
          <vs-col class="w-1/3">
            <vs-input
              label="Ramo de atuação"
              disabled="true"
              :value="dadosDoRisco.BranchActivity"
              size="large"
            />
          </vs-col>
          <vs-col class="w-1/3">
            <vs-input
              label="Ramo/CNAE Restrito?"
              disabled="true"
              :value="dadosDoRisco.BranchActivityIsRestrictive ? 'Sim' : 'Não'"
              size="large"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/3">
            <p>Fat. Presumido Anual</p>
            <currency-input
              v-model="dadosDoRisco.AnnualBillingAmount"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>
          <vs-col class="w-1/3">
            <p>Capital Social</p>
            <currency-input
              v-model="dadosDoRisco.ShareCapitalValue"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>

          <vs-col class="w-1/3">
            <p>Capacidade de pagamento</p>
            <currency-input
              v-model="dadosDoRisco.PaymentCapacity"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/4">
            <vs-input
              label="Porte"
              disabled="true"
              :value="dadosDoRisco.CompanySize"
              size="large"
            />
          </vs-col>

          <vs-col class="w-1/4">
            <vs-input
              class="w-full"
              label="Classe de Risco Financeiro"
              disabled="true"
              :value="dadosDoRisco.OnPointClass"
              size="large"
            />
          </vs-col>

          <vs-col class="w-1/4">
            <vs-input
              class="w-full"
              label="Score Final"
              disabled="true"
              :value="dadosDoRisco.FinalScore"
              size="large"
            />
          </vs-col>
          <vs-col class="w-1/4">
            <vs-input
              class="w-full"
              label="Risco Score"
              disabled="true"
              :value="dadosDoRisco.RiskClassScore"
              size="large"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/3"> <b>RESTRITIVOS DO TOMADOR:</b> </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="w-1/3">
            <p>Debitos Pefins</p>
            <currency-input
              v-model="dadosDoRisco.PefinValue"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>

          <vs-col class="w-1/3">
            <p>Debitos Refins</p>
            <currency-input
              v-model="dadosDoRisco.RefinValue"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>

          <vs-col class="w-1/4">
            <vs-input
              class="w-full"
              label="CCF"
              disabled="true"
              :value="dadosDoRisco.CCF"
              size="large"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/3">
            <p>Protestos</p>
            <currency-input
              v-model="dadosDoRisco.Protest"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>

          <vs-col class="w-1/3">
            <p>Dívidas Vencidas</p>
            <currency-input
              v-model="dadosDoRisco.DebitValue"
              disabled="true"
              class="w-full vs-inputx vs-input--input large hasValue"
            />
          </vs-col>

          <vs-col class="w-1/3">
            <p>Pontos</p>
            <vs-input
              v-model="dadosDoRisco.Points"
              size="large"
              disabled="true"
              class="w-full"
            />
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/4">
            <p>Data de Consulta Restritivos</p>
            <vs-input
              :value="
                new Date((tomador || {}).LastEvaluationRestrictive)
                  | dateFormat('DD/MM/YYYY')
              "
              size="large"
              disabled="true"
              class="w-full"
            />
          </vs-col>
        </vs-row>
      </template>

      <vs-row v-if="this.IsBlocked || this.IsBlockedRestrictive">
        <vs-col class="vx-col pt-5 w-full mt-2">
          <label>Justificativa de bloqueio</label>
          <vs-textarea disabled v-model="this.justificativa" />
        </vs-col>
      </vs-row>

      <vs-row
        class="mt-6"
        v-if="
          profileHelpers.checkPermission([
            profileHelpers.menuFuncionalitiesEnum
              .BLOQUEAR_RESTRITIVOS_DO_TOMADOR
          ])
        "
      >
        <vs-col class="vx-col pt-5 w-full mt-2">
          <vs-checkbox
            @change="blockPolicyHolderRestrictive()"
            v-model="IsBlockedRestrictive"
            >Bloqueado por restritivos</vs-checkbox
          >
        </vs-col>
      </vs-row>
    </div>

    <div id="dadosComplementares" class="tabContent">
      <form-endereco
        :dadosEndereco="endereco"
        :showOkButton="profileHelpers.isInsurance() && podeEditar"
        :disabled="!profileHelpers.isInsurance() || !podeEditar"
        @save="atualizarEndereco"
        v-show="endereco"
      >
        <vs-row>
          <ValidationProvider
            name="Telefone"
            rules="required"
            v-slot="{ errors }"
            tag="vs-col"
            class="vs-lg-6 vs-sm-12"
          >
            <vs-input
              class="w-full"
              label="Telefone"
              :disabled="!profileHelpers.isInsurance() || !podeEditar"
              v-model="phoneNumber"
              v-mask="['(##) ####-####', '(##) #####-####']"
              size="large"
            />
            <span class="text-danger text-sm" v-show="errors[0]">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </vs-row>
      </form-endereco>
    </div>

    <div id="analiseCredito" class="tabContent">
      <AnaliseCreditoComponent
        :tomador="tomadorDetail"
        v-if="isTabAnaliseCredito"
      />
    </div>
    <div id="dadosBancarios" class="tabContent">
      <br>
      <dadosBancariosComponent
        v-if="isTabBank"
        :mode="'edit'"
        :personId="tomadorDetail.Id"
        :personUniqueId="tomadorDetail.UniqueId"
      />
    </div>

    <vs-popup
      class="validacao-data-vigente-modal"
      :active.sync="openModalForcarAprovacao"
      title="Deseja realmente Forçar a aprovação deste tomador?"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button
            @click="openModalForcarAprovacao = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click="aprovarTomador(tomadorDetail.UniqueId, true)"
            class="button-primary"
          >
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      :active.sync="openModalReprocessamento"
      title="Deseja realmente reprocessar os dados deste tomador?"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button
            @click="openModalReprocessamento = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click="reprocessarTomador(tomadorDetail.UniqueId, false)"
            class="button-primary"
          >
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Atualizar comissão do tomador"
      :active.sync="showModalComission"
    >
      <div class="vw-row">
        <vs-input
          class="vx-col w-full mb-3"
          label="Comissão"
          v-model="paramModalComission"
          size="large"
        />
        <div class="vx-col w-full text-right">
          <vs-button
            @click="showModalComission = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click="updateComission()"
            :disabled="
              paramModalComission == null || paramModalComission == undefined
            "
            class="button-primary"
          >
            Salvar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
     class="modal-opcoes-corretor-nomeado"
      title="Opções"
      :active.sync="showModalCorretorOptions"
      width='200px'
    >
    <div class='popup-actions'>
      <div class="card-button" @click="fecharModalOpcoesCoretorNomeado(),exibirModalAtualizarCoretorNomeado()">
        <b> Editar corretor</b>
      </div>

      <div class="card-button" @click="fecharModalOpcoesCoretorNomeado(),disclamerDesnomear()">
        <b> Desnomear corretor</b>
      </div>
    </div>

    </vs-popup>

    <vs-popup
      class="modal-corretor-nomeado"
      title="Atualizar corretor nomeado"
      :active.sync="showModalAppointedBroker"
    >
      <div class="vw-row" v-if="!mudouCorretorNomeado">
        <vs-input
          class="vx-col w-full mb-3"
          label="Digite o CNPJ do novo corretor"
          v-model="paramModalAppointedBrokerCNPJ"
          size="large"
          v-mask="['##.###.###/####-##']"
        />
        <div class="vx-col w-full text-right">
          <vs-button
            @click="
              showModalAppointedBroker = false;
              showModalCorretorOptions =true;
              paramModalAppointedBrokerCNPJ = '';
            "
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click="buscarCorretorPorCNPJ(paramModalAppointedBrokerCNPJ)"
            class="button-primary"
            :disabled="disableSeachBrokerCNPJ"
          >
            Buscar
          </vs-button>
        </div>
      </div>

      <div class="vw-row" v-else>
        <div class="vx-col w-full mt-3 mb-5">
          <div class="vx-col w-full modal-corretor-nomeado-infos">
            <h6 class="modal-corretor-nomeado-infos-conteudo">
              {{ novoCorretorNomeado.NameSusep }}
            </h6>
            <p class="modal-corretor-nomeado-infos-conteudo">
              {{ adicionarMascaraCNPJ(novoCorretorNomeado.CpfCnpj) }}
            </p>
            <p class="modal-corretor-nomeado-infos-conteudo">
              {{ novoCorretorNomeado.LocationAddress }}
            </p>
          </div>

          <div class="vx-col w-full mt-5 modal-corretor-nomeado-validade">
            <span class="modal-corretor-nomeado-validade-titulo"
              >Data de validade da nomeação <strong>(opcional)</strong></span
            >
            <el-date-picker
              v-model="paramNomeationExpireDate"
              type="date"
              placeholder="Nova data"
              format="dd/MM/yyyy"
              value-format="yyyy/MM/dd"

            >
            </el-date-picker>
          </div>
        </div>
        <div class="vx-col w-full text-right">
          <vs-button
            @click="limparBuscaCorretorPorCNPJ()"
            class="button-default mr-5"
          >
            Limpar
          </vs-button>
          <vs-button
            @click="atualizarCoretorNomeado()"
            class="button-primary"
            :disabled="disableSaveNewAppointedBroker"
          >
            Salvar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Atualizar Data de validade cadastral"
      :active.sync="showModalDate"
    >
      <div class="vw-row">
        <el-date-picker
          v-model="paramModalDate"
          type="date"
          placeholder="Nova data"
          format="dd/MM/yyyy"
          value-format="yyyy/MM/dd"
        >
        </el-date-picker
        ><br /><br />

        <div class="vx-col w-full text-right">
          <vs-button @click="showModalDate = false" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button
            @click="updateDate()"
            :disabled="paramModalDate == null || paramModalDate == undefined"
            class="button-primary"
          >
            Salvar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Atualizar nome do tomador`"
      :active.sync="showModalUpdate"
    >
      <div class="vw-row">
        <vs-input
          class="vx-col w-full mb-3"
          label="Nome"
          v-model="paramModalUpdate"
          size="large"
        />
        <div class="vx-col w-full text-right">
          <vs-button
            @click="showModalUpdate = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click="updateName()"
            :disabled="
              paramModalUpdate == null || paramModalUpdate == undefined
            "
            class="button-primary"
          >
            Salvar
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      title="ConsultarWS"
      :active.sync="consultarWSDialog">
      <div>
        <div class="my-5">
          Deseja consultar no receita WS?
        </div>
        <div class="vx-col w-full text-right">
          <vs-button
            @click="consultarWSDialog = !consultarWSDialog"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            class="button-primary"
            @click="fecthConsultarWS()"
          >
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      title="Alerta!"
      :active.sync="consultarWSDialogMsg"
      class="text-center"
    >
      <div class="text-center vw-row">
        O processo de consulta está em andamento. Isso pode levar aproximadamente 5 minutos. Os resultados só serão atualizados na tela após uma nova proposta ser utilizada
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mask, TheMask } from "vue-the-mask";
import AdicionarFilial from "./AdicionarFilial.vue";
import CartaNomeacao from "./CartaNomeacao.vue";
import FormEndereco from "@/components/enderecos/FormEndereco.vue";
import instance from "@/axios";
import { mapActions, mapMutations, mapGetters } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import environment from "@/environment";
import moment from "moment";
import AnaliseCreditoComponent from "./AnaliseCredito.vue";
import dadosBancariosComponent from "./dadosBancarios.vue";

import axiosInstance from "@/axios";

export default {
  name: "dados-gerais",
  props: {
    dadoCNAE: {
      type: Object,
      required: true
    },
    endereco: {
      type: Object,
      required: true
    },
    telefone: {
      type: undefined,
      required: true
    }
  },
  directives: { mask },
  components: {
    TheMask,
    AdicionarFilial,
    CartaNomeacao,
    FormEndereco,
    AnaliseCreditoComponent,
    dadosBancariosComponent
  },
  data() {
    return {
      // alguns campos não foram preenchidos -> precisa ver a api
      label: "",
      phoneNumber: "",
      justificativa: "",
      IsBlockedRestrictive: false,
      IsBlocked: false,
      tomadorDetail: Object,
      openModalReprocessamento: false,
      openModalForcarAprovacao: false,
      permiteForcarAprovacao: false,
      permiteReprocessamento: false,
      showModalUpdate: false,
      paramModalUpdate: null,
      profileHelpers: PerfilHelpers,
      showModalDate: false,
      paramModalDate: 0,
      showModalComission: false,
      paramModalComission: 0,
      showModalAppointedBroker: false,
      showModalCorretorOptions:false,
      paramModalAppointedBrokerCNPJ: "",
      paramNomeationExpireDate: "",
      disableSeachBrokerCNPJ: false,
      disableSaveNewAppointedBroker: false,
      novoCorretorNomeado: "",
      mudouCorretorNomeado: false,
      today: "01/01/2023",
      isTabAnaliseCredito: false,
      isTabBank: false,
      consultarWSDialog:false,
      consultarWSDialogMsg:false
    };
  },
  computed: {
    ...mapGetters("tomador-module", ["tomador"]),
    dadosDoRisco: {
      get() {
        const dadosDoRisco = (this.tomador || {}).PersonRisk;
        return dadosDoRisco;
      }
    },

    podeEditar() {
      return this.profileHelpers.checkPermission([
        this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
        this.profileHelpers.menuFuncionalitiesEnum
          .GERENCIAR_TOMADORES_SEGURADORA
      ]);
    },
    userInfo() {
      return this.$store.getters["auth/userInfo"];
    },
  },
  mounted() {
    this.tomadorDetail = this.$utils.deepClone(this.tomador);
    console.log(this.tomadorDetail);
    // if (!this.tomadorDetail.RevisionDate) {
    //   console.log(this.tomadorDetail)
    //   this.tomadorDetail.RevisionDate = 'teste';
    // }
    this.IsBlockedRestrictive = this.tomadorDetail.IsBlockedRestrictive;
    this.IsBlocked = this.tomadorDetail.IsBlocked;
    this.montaJustificativaBlockTomador(this.tomadorDetail);
    this.phoneNumber = (this.telefone || {}).Number;
    this.validarSeHaEmissoes(this.tomadorDetail.UniqueId);

    this.today = new Date();
  },
  methods: {
    ...mapActions("tomador-module", [
      "inverterIgnoreCCGSignature",
      "blockUnblockPolicyHolderRestrictive",
      "carregarTomador",
      "saveComplementarData",
      "removeLocation",
      "updatePolicyHolderName"
    ]),
    ...mapMutations("tomador-module", ["updateTomador"]),

    updateRevision() {
      console.log(this.tomadorDetail.RevisionDate);
      this.$forceUpdate();
      // this.tomadorDetail.RevisionDate = '11/05/1997'
    },
    openModalUpdateComission() {
      this.paramModalComission = this.$utils.deepClone(
        this.tomadorDetail.ComissionPercentage
      );
      this.showModalComission = true;
    },
    exibirModalOpcoesCoretorNomeado() {
      this.showModalCorretorOptions = true;
    },
    fecharModalOpcoesCoretorNomeado() {
      this.showModalCorretorOptions = false;
    },
    exibirModalAtualizarCoretorNomeado() {
      this.showModalAppointedBroker = true;
    },
    fecharModalAtualizarCoretorNomeado() {
      this.showModalAppointedBroker = false;
      this.mudouCorretorNomeado = false;
      this.paramNomeationExpireDate = "";
      this.paramModalAppointedBrokerCNPJ = "";
      this.novoCorretorNomeado = "";
    },
    disclamerDesnomear() {
      this.$confirm(
        ' Tem certeza que deseja desnomear o corretor? Após a confirmação  de desnomeação não será possível a sua reversão.',
        "Desnomear corretor?",
        {
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar"
        }
      )
        .then(() => {
          this.desnomearCorretor();
        })
        .catch(() => {
          this.exibirModalOpcoesCoretorNomeado()
        });
    },
    async desnomearCorretor(){
      await this.$onpoint.loading(async() => {
        return await axiosInstance({
          method: "post",
          url:`api/PolicyHolder/RegisterPolicyHolderRemoveNameRequest`,
          data: {
            PolicyHolderId: this.tomadorDetail.Id,
            BrokerId: this.novoCorretorNomeado.Id,
            NomeationExpireDate: this.paramNomeationExpireDate
          }
        }).then((response)=>{
          this.tomadorDetail.NominatedToBroker = response.NominatedToBroker;
          this.updateTomador(this.tomadorDetail);
          this.$forceUpdate();
        })
      });
    },
    adicionarMascaraCNPJ(numeros) {
      return numeros.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    removerMascaraCNPJ(cnpj) {
      return cnpj.replace(/[^\d]/g, "");
    },
    buscarCorretorPorCNPJ(cnpj) {
      const cnpjNovoCorretor = this.removerMascaraCNPJ(cnpj);
      this.disableSeachBrokerCNPJ = true;

      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`api/Broker/GetBroker?cnpj=${cnpjNovoCorretor}`)
          .then(response => {
            this.disableSeachBrokerCNPJ = false;
            this.novoCorretorNomeado = response.data.Response;
            this.mudouCorretorNomeado = true;
          })
          .catch(err => {
            this.paramModalAppointedBrokerCNPJ = "";
            this.disableSeachBrokerCNPJ = false;
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },
    limparBuscaCorretorPorCNPJ() {
      this.mudouCorretorNomeado = false;
      this.paramModalAppointedBrokerCNPJ = "";
      this.novoCorretorNomeado = "";
    },
    atualizarCoretorNomeado() {
      this.disableSaveNewAppointedBroker = true;

      this.$onpoint.loading(() => {
        return instance({
          method: "post",
          url: `/api/PolicyHolder/RegisterPolicyHolderInsuranceNomeationRequest`,
          data: {
            PolicyHolderId: this.tomadorDetail.Id,
            BrokerId: this.novoCorretorNomeado.Id,
            NomeationExpireDate: this.paramNomeationExpireDate
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$onpoint.successModal(
              "Corretor nomeado atualizado com sucesso!"
            );
            this.tomadorDetail.NominatedToBroker = response.NominatedToBroker;
            this.tomadorDetail.NomeationExpireDate = response.NomeationExpireDate;
            this.updateTomador(this.tomadorDetail);
            this.$forceUpdate();
            this.disableSaveNewAppointedBroker = false;
            this.fecharModalAtualizarCoretorNomeado();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.disableSaveNewAppointedBroker = false;
            this.fecharModalAtualizarCoretorNomeado();
          });
      });
    },
    virgulaPorPonto(value) {
      return value.toString().replace(",", ".");
    },
    updateComission() {
      this.paramModalComission = this.virgulaPorPonto(this.paramModalComission);
      this.$onpoint.loading(() => {
        this.openModalReprocessamento = false;
        return instance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderComission`,
          data: {
            PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
            Comission: this.paramModalComission
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$onpoint.successModal("Comissão atualizada com sucesso!");
            this.tomadorDetail.ComissionPercentage = response.ComissionPercentage.toString().replace(
              ".",
              ","
            );
            this.updateTomador(this.tomadorDetail);
            this.showModalComission = false;
            this.paramModalComission = false;
            this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalComission = false;
          });
      });
    },
    updateDate() {
      this.$onpoint.loading(() => {
        this.showModalDate = false;
        return instance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderExpirationDate`,
          data: {
            PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
            ExpirationDate: this.paramModalDate
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$onpoint.successModal("Data atualizada com sucesso!");

            this.tomadorDetail.ExpirationDate = moment(this.paramModalDate).add(
              0,
              "days"
            );
            this.showModalDate = false;
            this.paramModalDate = 0;

            // this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalDate = false;
          });
      });
    },
    analiseCredito() {
      this.$onpoint.loading(() => {
        this.showModalDate = false;
        return instance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderCreditAnalysis`,
          data: {
            PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
            PaymentCapacity: this.tomadorDetail.PaymentCapacity,
            RealRevenue: this.tomadorDetail.RealRevenue,
            CompanySize: this.tomadorDetail.CompanySize,
            NetWorth: this.tomadorDetail.NetWorth,
            Observations: this.tomadorDetail.Observations,
            RevisedLimit: this.tomadorDetail.RevisedLimit,
            RevisionDate: this.tomadorDetail.RevisionDate
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Tomador Atualizado  com sucesso!`,
              color: "success"
            });
            // this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    verificarMapfreCorretor() {
      if (
        environment.CompanyId.Mapfre ==
        this.$store.state["auth"].userInfo.InsuranceId
      ) {
        return true;
      } else {
        return false;
      }
    },

    filterCNAEs(CNAE) {
      let primaryCNAE = null;
      (CNAE || []).map(function(element) {
        if (element.IsPrimary == true) {
          primaryCNAE = element.CodeCNAE;
        }
      });
      return primaryCNAE;
    },

   async invertCcgSignature(tomadorUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.inverterIgnoreCCGSignature({
          PolicyHolderUniqueId: tomadorUniqueId
        }).then(resp => {
          this.tomadorDetail.IgnoreCCGSignature = resp;

          this.updateTomador(this.tomadorDetail);
        });
      });
    },

    async blockPolicyHolderRestrictive() {
      await this.$onpoint.loading(async () => {
        return await this.blockUnblockPolicyHolderRestrictive({
          id: this.tomadorDetail.Id,
          IsBlockedRestrictive: this.IsBlockedRestrictive
        })
          .then(resp => {
            const action = !resp.IsBlockedRestrictive
              ? "desbloqueado"
              : "bloqueado";
            this.$vs.notify({
              title: "Sucesso",
              text: `Tomador ${action} com sucesso!`,
              color: "success"
            });
            this.justificativa = "";
            this.montaJustificativaBlockTomador(resp);
            this.IsBlockedRestrictive = resp.IsBlockedRestrictive;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    openModalUpdateName() {
      this.paramModalUpdate = this.$utils.deepClone(this.tomadorDetail.Name);
      this.showModalUpdate = true;
    },
    openModalUpdateDate() {
      this.paramModalDate = "";
      this.showModalDate = true;
    },
    openModalUpdateComission() {
      this.paramModalComission = this.$utils.deepClone(
        this.tomadorDetail.ComissionPercentage
      );
      this.showModalComission = true;
    },
    updateComission() {
      this.paramModalComission = this.virgulaPorPonto(this.paramModalComission);
      this.$onpoint.loading(() => {
        this.openModalReprocessamento = false;
        return instance({
          method: "post",
          url: `/api/PolicyHolder/UpdatePolicyHolderComission`,
          data: {
            PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
            Comission: this.paramModalComission
          }
        })
          .then(response => response.data.Response)
          .then(response => {
            this.$onpoint.successModal("Comissão atualizada com sucesso!");
            this.tomadorDetail.ComissionPercentage = response.ComissionPercentage.toString().replace(
              ".",
              ","
            );
            this.updateTomador(this.tomadorDetail);
            this.showModalComission = false;
            this.paramModalComission = false;
            this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalComission = false;
          });
      });
    },
    virgulaPorPonto(value) {
      return value.toString().replace(",", ".");
    },
    async updateName() {
      let payload = {
        PolicyHolderUniqueId: this.tomadorDetail.UniqueId,
        Name: this.paramModalUpdate
      };
      await this.$onpoint.loading(async () => {
        return await this.updatePolicyHolderName(payload)
          .then(resp => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Segurado atualizado com sucesso!`,
              color: "success"
            });
            this.tomadorDetail.Name = payload.Name;
            this.updateTomador(this.tomadorDetail);
            this.showModalUpdate = false;
            this.$forceUpdate();
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalUpdate = false;
          });
      });
    },

    async reprocessarTomador(policyHolderUniqueId, Aprove) {
      if (this.permiteReprocessamento) {
       await this.$onpoint.loading( async() => {
          this.openModalReprocessamento = false;
          return await instance({
            method: "post",
            url: `/api/PolicyHolder/PolicyHolderReprocessing?PolicyHolderUniqueId=${policyHolderUniqueId}&ForceAprove=${Aprove}`
          })
            .then(response => response.data.Response)
            .then( async response => {
              this.$onpoint.successModal("Operação realizada com sucesso!");
             await this.carregarTomador(response.UniqueId);
              this.updateTomador(this.tomador);
            })
            .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
        });
      } else {
        this.openModalReprocessamento = false;
        this.$onpoint.errorModal("Este tomador possui apólices emitidas.");
      }
    },
   async aprovarTomador(policyHolderUniqueId, Aprove) {
      await this.$onpoint.loading( async() => {
        this.openModalForcarAprovacao = false;
        return await instance({
          method: "post",
          url: `/api/PolicyHolder/PolicyHolderReprocessing?PolicyHolderUniqueId=${policyHolderUniqueId}&ForceAprove=${Aprove}`
        })
          .then(response => response.data.Response)
          .then( async response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            await this.carregarTomador(response.UniqueId);
            this.updateTomador(this.tomador);
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    validarSeHaEmissoes(policyHolderUniqueId) {
      this.$onpoint.loading(() => {
        return instance({
          method: "get",
          url: `/api/Policy/ValidatePolicyHolderHasIssuedPolicies?PolicyHolderUniqueId=${policyHolderUniqueId}`
        })
          .then(response => response.data.Response)
          .then(response => {
            this.permiteReprocessamento = !response;
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    montaJustificativaBlockTomador(policyHolder) {
      if (
        policyHolder != undefined &&
        policyHolder.IsBlocked != undefined &&
        policyHolder.IsBlockedRestrictive != undefined
      ) {
        if (policyHolder.IsBlocked && policyHolder.BlockJustify != null)
          this.justificativa = policyHolder.BlockJustify;
        if (
          policyHolder.IsBlockedRestrictive &&
          policyHolder.BlockRestrictiveJustify != null
        )
          this.justificativa =
            this.justificativa +
            (this.justificativa == ""
              ? policyHolder.BlockRestrictiveJustify
              : " e " + policyHolder.BlockRestrictiveJustify);
      }
    },

    openTab(event, tabName) {
      console.log(tabName);
      if (tabName == "analiseCredito") {
        this.isTabAnaliseCredito = true;
      } else {
        this.isTabAnaliseCredito = false;
      }
      if (tabName == "dadosBancarios") {
        this.isTabBank = true;
      } else {
        this.isTabBank = false;
      }
      [...document.getElementsByClassName("tabContent")].forEach(content => {
        content.classList.remove("active");
      });
      [...document.getElementsByClassName("tabLinks")].forEach(link => {
        link.classList.remove("active");
      });
      document.getElementById(tabName).classList.add("active");
      event.currentTarget.classList.add("active");
    },

    async atualizarEndereco(location) {
      let payload = {
        ...location,
        PersonId: this.tomador.Id,
        PhoneNumber: this.phoneNumber
      };
      await this.$onpoint.loading(async () => {
        return await this.saveComplementarData(payload)
          .then(resp => {
            this.updateTomador({ ...this.tomador, ...resp });
            this.$vs.notify({
              title: "Sucesso",
              text: `Endereço salvo com sucesso!`,
              color: "success"
            });

            this.$emit("updateSupplementaryData", {
              location: location,
              phone: this.phoneNumber
            });
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    async removeEndereco(id) {
    await this.$onpoint.loading( async() => {
        return await this.removeLocation({
          locationId: id
        })
          .then(resp => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Endereço removido com sucesso!`,
              color: "success"
            });
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    formatarData(date) {
      if (date instanceof Date) {
        date = date.setHours(0, 0, 0, 0);
      }
      return moment(date).format("DD/MM/YYYY");
    },

     async fecthConsultarWS(){
      this.consultarWSDialog = false
      this.consultarWSDialogMsg = true
      await instance({
        method: "post",
        url: `/api/PolicyHolder/UpdateReceitaByCNPJ?Cnpj=${this.tomadorDetail.CpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}`,
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.tab {
  overflow: hidden;
  background-color: white;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: white;
  float: left;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  padding: 10px 14px;
  transition: 0.3s;
  font-size: inherit;
  color: #848484;
  border-radius: 0 !important;
  font-family: inherit;
}

/* Create an active/current tablink class */
.tab button.active {
  color: rgba(var(--vs-warning), 1);
  border-bottom: 2px solid rgba(var(--vs-warning), 1);
}

/* Style the tab content */
.tabContent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: 2px solid #d9d9d9;
  margin-top: -2px;
}

.tabContent.active {
  display: block;
}

.enable-ccg .material-icons {
  font-size: 0.85rem;
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
}

.margin-full {
  padding: 0 1rem;
}

.mt-6 {
  margin-top: 1.25rem !important;
}

.modal-corretor-nomeado {
  &-infos {
    padding: 14px;
    border-radius: 8px;
    background: #eee;

    &-conteudo {
      margin: 0 0 10px 0;
    }
  }

  &-validade {
    &-titulo {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
  .popup-actions{
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .card-button {
    margin: 10px;
    position: relative;
    width: 240px;
    height: 100px;
    background: #f9fafb;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    padding: 20px 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
  }
  .card-button:hover {
    box-shadow: 10px 10px 5px 0px rgba(var(--vs-primary), 0.45);
  }
</style>
